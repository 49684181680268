/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export function isArrayBufferEqual(
 a: ArrayBufferLike | undefined | null,
 b: ArrayBufferLike | undefined | null
): boolean {
 if (a == null || b == null) {
 return false;
 }

 if (a === b) {
 return true;
 }

 if (a.byteLength !== b.byteLength) {
 return false;
 }

 const viewA = new DataView(a);
 const viewB = new DataView(b);

 for (let i = 0; i < a.byteLength; i++) {
 if (viewA.getUint8(i) !== viewB.getUint8(i)) {
 return false;
 }
 }

 return true;
}
